.form-control {
  margin-bottom: 20px;
}

.form-control label {
  display: block;
  margin-bottom: 10px;
}

.form-control input[type='checkbox'] {
  margin-top: 0;
}

.form-control select {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
}
