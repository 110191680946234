.transaction {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15px;
  padding-top: 30px;
}

.transaction__image {
  width: 156px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 15px;
}
.success__image {
  margin: 15px;
  width: 156px;
  height: 150px;
  border-radius: 50%;
}
.transaction__instructions {
  margin-bottom: 5px;
}

.transaction__form {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.transaction__form button {
  width: 100%;
}

.oilCardWrapper {
  min-width: 340px;
}

@media (min-width: 700px) {
  .transaction {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 60px;
  }

  .oilCardWrapper {
    min-width: 450px;
  }
}

@media (min-height: 800px) {
  .transaction__image {
    margin-bottom: 50px;
  }
}

.success-box {
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  color: #155724;
  padding: 20px;
  margin: 20px auto;
  max-width: 400px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin: 25px;
}

.success-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
}

.magna-amount {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.transaction-id {
  font-size: 28px;
  font-weight: bold;
  margin-top: 10px;
}

.error-box {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  color: #721c24;
  padding: 20px;
  margin: 20px auto;
  max-width: 400px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin: 25px;
}

.error-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
}

.error-code {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
}
