.accounts {
  display: flex;
  flex-direction: column;
  justify-content: left;
  min-height: 150px;
  display: flex;
  flex-wrap: wrap;
}

.accountLoading {
  display: flex;
  justify-content: center;
  min-height: 150px;
  align-items: center;
}

.account-card {
  position: relative;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  color: #212121;
  background-color: white;
  min-width: 200px;
  margin: 5px;
}

.account-card__balance-spotlight {
  background-color: #f5f5f5; /* Light grey background for the balance */
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 20px;
}

.account-card__balance {
  margin: 0;
  font-size: 2em;
  font-weight: bold;
}

.account-card__balance--negative {
  color: #d32f2f;
}

.account-card__info-text {
  margin: 0;
  padding: 10px 0;
  color: #757575;
  font-size: 12px;
}

.account-card__filter {
  border: none;
  background-color: transparent;
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

@media (min-width: 768px) {
  .accounts {
    flex-direction: row;
  }

  .account-card {
    min-width: 300px;
    margin: 15px;
  }
}
