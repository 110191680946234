.circularButton {
  position: -webkit-sticky; /* Safari */
  position: fixed;
  right: 15px;
  bottom: 25px;
  z-index: 1000;
  background-color: black;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: 0px solid transparent;
  color: whitesmoke;
  font-size: 22px;
  cursor: pointer;
}

.circularButton:hover {
  height: 70px;
  width: 70px;
}
