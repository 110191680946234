.input {
  display: flex;
  flex-direction: column;
}

.input__label {
  margin-bottom: 7px;
  font-weight: 600;
  font-size: 16px;
}

.input__input {
  height: 30px;
  width: 250px;
  border-radius: 5px;
  font-size: 18px;
  border: 1px solid pink;
  padding: 5px;
}

.input__error {
  color: red;
  font-weight: 500;
  margin: 0px 0px 15px 0px;
}
