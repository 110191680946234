.emptyOil {
  width: 170px;
  height: 170px;
  border-radius: 50%;
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.empty__title {
  font-weight: 500px;
  margin: 0px;
  padding: 0px;
}

.empty__subtitle {
  margin: 5px;
  color: black;
  font-size: 14px;
}
