.overview__items {
  display: grid;
}

.statistic,
.statistic--loading {
  border-radius: 9px;
  padding: 15px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 2.5px;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.statistic--loading {
  justify-content: center;
  align-items: center;
}

.statistic p {
  margin: 5px;
}

.statistic button {
  background-color: transparent;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  font-size: 15px;
}

.statistic__value {
  font-size: 1.25rem;
  font-weight: 700px;
}

/* Medium */
@media (min-width: 768px) {
  .overview__items {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 2fr 2fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    justify-items: stretch;
    align-items: stretch;
  }
}
