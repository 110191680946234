.oilCard {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
}

.oilCard__info {
  padding: 15px;
}

.oilCard__actions {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.oilCard__buttonMark {
  background-color: green;
  color: whitesmoke;
  height: 40px;
  padding: 10px;
  margin: 5px;
  border: 1px solid grey;
  border-radius: 12px;
  cursor: pointer;
}

.oilCard__button--good {
  background-color: green;
  color: whitesmoke;
  height: 40px;
  width: 100px;
  margin: 5px;
  border: 1px solid grey;
  border-radius: 12px;
  cursor: pointer;
}

.oilCard__button--bad {
  background-color: transparent;
  color: black;
  height: 40px;
  width: 100px;
  margin: 5px;
  border: 1px solid grey;
  border-radius: 12px;
  cursor: pointer;
}

.oildCard__token {
  color: gray;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 22px;
  font-weight: 600;
}

.oilCard__title {
  text-transform: capitalize;
  font-size: 20px;
  margin-left: 0px;
  font-weight: bold;
  margin-top: 15px;
}

.hideIconContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

/* Medium	 */
@media (min-width: 768px) {
  .oilCard__badge {
    right: -720px;
  }
}

.ribbon {
  background-color: #007bff; /* Color de fondo del listón */
  color: white; /* Color del texto */
  padding: 5px 10px; /* Espaciado interno */
  position: relative; /* Posicionamiento absoluto respecto al div padre */
  top: 0; /* Alineado al top del div padre */
  left: 0; /* Alineado a la izquierda del div padre */
  clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%); /* Forma del listón */
}
