body {
  margin: 0px;
  background-color: #fafafa;
  padding: 0px;
}

nav {
  margin: 0px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
}
