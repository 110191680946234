.button {
  border: transparent;
  color: rgb(240, 228, 228);
  background-color: rgb(14, 14, 14);
  width: 200px;
  height: 50px;
  margin-top: 15px;
  border-radius: 12px;
  cursor: pointer;
  text-transform: capitalize;
}

.button:disabled {
  background-color: gray;
}
