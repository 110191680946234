.tabBar__list {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  background-color: black;
  color: whitesmoke;
  height: 60px;
  margin: 0px;
  padding: 5px;
}

.tabBar__listElement {
  margin-left: 5px;
  margin-right: 5px;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  width: 100px;
  padding: 5px;
  text-align: center;
}

.tabBar__listElement--active {
  border-bottom: 2px solid white;
}

.tabBar__listElement:hover {
  border-bottom: 2px solid white;
}

@media (min-width: 750px) {
  .tabBar__list {
    padding: 15px;
  }
  .tabBar__listElement {
    margin-left: 15px;
    margin-right: 15px;
    width: 150px;
  }
}
