.avatar {
  margin: "1px solid grey";
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.avatars {
  display: flex;
}
