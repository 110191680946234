.description {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 15px;
}

.description p {
  margin: 0px;
}

.description__key {
  margin-right: 5px;
  font-weight: 500;
  text-transform: capitalize;
}

.description__value {
  text-transform: capitalize;
}
