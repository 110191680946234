.userBar {
  background-color: black;
  padding: 15px 20px;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid darkslategray;
}

.userAvatar {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: green;
  color: #fff;
  border-radius: 50%;
  height: 48px;
  width: 48px;
  border: none;
  cursor: pointer;
}

.avatar__letters {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
}

.userMenu {
  list-style: none;
}

.profileInfo {
  background-color: white;
  position: absolute;
  width: 200px;
  min-height: 100px;
  right: 0px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.userInfo {
  height: 80px;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  border-bottom: 5px solid #f3f2f1;
}

.profileInfo ul {
  list-style: none;
  padding: 0px 15px;
  font-size: 14px;
  color: #424242;
}

.nameLabel {
  font-size: 16px;
}

.subLabel {
  font-size: 13px;
  color: #424242;
}

.menu__option {
  cursor: pointer;
}

.menu__option:hover {
  background-color: #f3f2f1;
}
