body {
    font-family: 'Roboto', sans-serif;
    background-color: #f5f5f5;
  }
  
  .container {
    width: 80%;
    margin: 50px auto;
  }
  
  #products {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 15px;
  }
  
  #products th, #products td {
    text-align: left;
    padding: 16px;
    vertical-align: middle;
  }
  
  #products th {
    color: rgba(0,0,0,0.54);
  }
  
  .product-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.14), 0 1px 3px 0 rgba(0,0,0,0.12);
    transition: background-color 0.3s ease;
  }
  
  .product-card:hover {
    background-color: #eee;
  }
  
  .edit {
    background-color: #3f51b5;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .edit:hover {
    background-color: #303f9f;
  }
  