.section {
  padding: 12px 24px;
}

.subtitle {
  margin: 5px;
  margin-bottom: 15px;
}

.section__title {
  width: 100%;
  margin: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section__title button {
  margin: 0px;
  border-radius: 22px;
  padding: 10px 10px;
  background-color: black;
  color: white;
  box-shadow: none;
  border: none;
  cursor: pointer;
  width: 150px;
  height: 45px;
}

/* Medium */
@media (min-width: 768px) {
  .section {
    padding: 12px 48px;
  }
}

/* Large */
@media (min-width: 768px) {
  .section {
    padding: 12px 60px;
  }
}

/* Extra Large */
@media (min-width: 1200px) {
  .section {
    padding: 12px 200px;
  }
}

/*Extra extra large*/
@media (min-width: 1400px) {
  .section {
    padding: 12px 450px;
  }
}
