.statusBadge {
  width: 70px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 8px;
  font-size: 12px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: bold;
}

.statusBadge--review {
  background-color: #795408;
}

.statusBadge--aproved {
  background-color: green;
}

.statusBadge--dispatched {
  background-color: rgb(55, 50, 50);
}

.statusBadge--rejected {
  background-color: red;
}

.statusBadge--paidOut {
  background-color: purple;
}

.statusBadge--paymentConfirmed {
  background-color: tomato;
}
