.formLogin {
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 320px;
  margin-top: 30px;
  border-radius: 12px;
  padding: 15px;
}

.sectionLogin {
  overflow: scroll;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: black;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}

.alternativeSection {
  display: flex;
  justify-content: space-evenly;
  margin-top: 15px;
  width: 100%;
  padding: 5px;
}

.createAccountLink {
  cursor: pointer;
  border: none;
  font-size: 16px;
  text-decoration: underline;
}

.forgotPasswordLink {
  font-size: 14px;
  position: relative;
  top: -10px;
  left: 54px;
  cursor: pointer;
  text-decoration: underline;
}

.forgotPasswordLabel {
  margin: 0;
}
