.modal {
  background-color: rgb(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.newVoucher {
  opacity: 1;
  width: 350px;
  background-color: white;
  border-radius: 12px;
  padding: 15px;
  box-sizing: border-box;
}

.newVoucher__content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 15px;
}

.newVoucher__close {
  display: flex;
  font-size: 22px;
  justify-content: flex-end;
  cursor: pointer;
}

.react-select-container {
  width: 269px;
  padding: 5px;
  margin-bottom: 15px;
  font-size: 18px;
}
